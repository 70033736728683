import React from 'react';
import { render } from 'react-dom';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/custom.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'));
