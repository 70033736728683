import React, { Component } from 'react';
import { Card, Button, Image, Carousel, Container, Row, Col } from 'react-bootstrap';
import { httpHandleError } from '../framework';
import axios from '../axios';
import i18n from '../i18n';
import SocialWidget from '../components/SocialWidget';
import SocialSmallWidget from '../components/SocialSmallWidget';
class MainPage extends Component {
    state = {
        banners: [],
        highlights: [],
        topics: []
    }
    initialData = this.initialData.bind(this);
    openUrl = this.openUrl.bind(this);

    async componentDidMount() {
        await this.initialData();
    }

    async initialData() {
        try {
            const responseBanner = await axios.post(`banner/search`, { isActive: true });
            const responseTopicHighlight = await axios.post(`topic/search`, { isHighlight: true, isActive: true });
            const responseTopic = await axios.post(`topic/search`, { isHighlight: false, isActive: true });
            if (responseBanner.data && responseBanner.data.banners) this.setState({ banners: responseBanner.data.banners });
            if (responseTopicHighlight.data && responseTopicHighlight.data.topics) this.setState({ highlights: responseTopicHighlight.data.topics });
            if (responseTopic.data && responseTopic.data.topics) this.setState({ topics: responseTopic.data.topics });
        } catch (error) {
            console.log(i18n.t('COMMON.ERROR'), httpHandleError(error));
        }
    }

    openUrl(url) {
        if (url) window.open(url);
    }

    render() {
        return (
            <div className="d-flex flex-column">
                {this.state.banners && this.state.banners.length > 0 &&
                    <Carousel className="mt-4 shadow">
                        {
                            (this.state.banners || []).map((banner) => {
                                return (
                                    <Carousel.Item key={banner._id} onClick={this.openUrl.bind(this, banner.url)}>
                                        <img
                                            className="d-block w-100"
                                            src={`${axios.defaults.baseURL}/file/download/${banner.imageId}`}
                                        />
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                }
                <div className="d-flex flex-row mt-3">
                    <Container>
                        <Row className="justify-content-start">
                            <Col className="p-0" xl={7}>
                                {
                                    (this.state.highlights || []).map((highlight) => {
                                        return (
                                            <Card key={highlight._id} className="p-0 mt-0 mr-2 mb-2 border-0" style={{ backgroundColor: 'transparent' }}>
                                                <Card.Body className="pl-0">
                                                    <div className="d-flex flex-row">
                                                        <Image className="mr-4 shadow" src={`${axios.defaults.baseURL}/file/download/${highlight.imageId}`} style={{ height: 210, width: 151 }} />
                                                        <div className="d-flex flex-column">
                                                            <h3 className="font-prompt" style={{ textDecoration: 'underline' }}>{highlight.name}</h3>
                                                            <div className="font-prompt text-line-8">{highlight.description}</div>
                                                            <div className="mt-3 mr-3 d-flex flex-row justify-content-end">
                                                                {
                                                                    highlight.url &&
                                                                    <Button variant="primary" onClick={this.openUrl.bind(this, highlight.url)}>
                                                                        {i18n.t('COMMON.READMORE')}
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        );
                                    })
                                }
                            </Col>
                            <Col className="p-0 d-flex flex-column align-items-xl-end align-items-center" xl={5}>
                                <SocialWidget className="d-none d-xl-block mt-3 mb-4" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="mb-3 d-flex flex-column flex-fill">
                    <h3 className="font-prompt" style={{ textDecoration: 'underline' }}>{i18n.t('COMMON.TOPIC')}</h3>
                    <Container>
                        <Row>
                            {(this.state.topics || []).map((topic) => {
                                return (
                                    <Col key={topic._id} className="p-0" md={6}>
                                        <Card className={"p-0 mt-0 mr-4 mb-2 border-0" + (topic.url ? " hover-zoom cursor-pointer" : "")} onClick={this.openUrl.bind(this, topic.url)} style={{ backgroundColor: 'transparent' }}>
                                            <Card.Body className="pl-0">
                                                <div className="d-flex flex-row">
                                                    <Image className="mr-4 shadow" roundedCircle src={`${axios.defaults.baseURL}/file/download/${topic.imageId}`} style={{ height: 100, width: 100 }} />
                                                    <div className="d-flex flex-column">
                                                        <div className="font-prompt text-line-5">{topic.description}</div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </div>
                <div className="d-flex flex-row justify-content-center">
                    <SocialSmallWidget className="d-xl-none mt-3 mb-4" />
                </div>
            </div >
        );
    }
}

export default MainPage;
