import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { IoMdExpand, IoMdContract } from 'react-icons/io';
import { isMobile } from "react-device-detect";
import i18n from '../i18n';

export default function FullScreen(props) {
    const [fullScreenMode, setFullScreenMode] = useState(false);
    let resizeTimeout = null;

    useEffect(() => {
        function onResize() {
            if (resizeTimeout) clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                setFullScreenMode(window.screen.height === window.innerHeight);
            }, 500);
        }

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [fullScreenMode]);


    /* View in fullscreen */
    function openFullscreen() {
        if (props.onChange) props.onChange();
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    /* Close fullscreen */
    function closeFullscreen() {
        if (props.onChange) props.onChange();
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozExitFullscreen) { /* Safari */
            document.mozExitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip hide={isMobile ? true : undefined}>
                    {fullScreenMode ? i18n.t('COMMON.EXITFULLSCREEN') : i18n.t('COMMON.FULLSCREEN')}
                </Tooltip>
            }
        >
            {fullScreenMode ?
                <Button className="button-circle mr-2" variant="primary" onClick={closeFullscreen} >
                    <IoMdContract size={19} />
                </Button> :
                <Button className="button-circle mr-2" variant="primary" onClick={openFullscreen} >
                    <IoMdExpand size={19} />
                </Button>
            }
        </OverlayTrigger>
    );
}