import React, { Component } from 'react';
import { Card, Image, Tabs, Tab } from 'react-bootstrap';
import MainPage from './MainPage';
import JournalList from './JournalList';
import AboutPage from './AboutPage';
import i18n from '../i18n';
import moment from 'moment';

class HomePage extends Component {
    state = {
        defaultTab: localStorage.getItem('tab') || "home"
    };
    saveTab = this.saveTab.bind(this);

    saveTab(tabName) {
        localStorage.setItem('tab', tabName);
    }

    render() {
        return (
            <div className="h-100">
                <Card className="h-100" style={{ borderWidth: 0, borderRadius: 0 }}>
                    <Card.Body className="pt-3 pr-3 pb-0 pl-3">
                        <Tabs className="tabs-theme justify-content-center" defaultActiveKey={this.state.defaultTab} variant="pills" >
                            <Tab tabClassName="tab-theme font-athiti-700" eventKey="home" onEntered={this.saveTab.bind(this, "home")} title={i18n.t('MENU.MAIN')}>
                                <MainPage {...this.props} />
                            </Tab>
                            <Tab tabClassName="tab-theme font-athiti-700" eventKey="journal" onEntered={this.saveTab.bind(this, "journal")} title={i18n.t('MENU.JOURNAL')}>
                                <JournalList {...this.props} />
                            </Tab>
                            <Tab tabClassName="tab-theme font-athiti-700" eventKey="about" onEntered={this.saveTab.bind(this, "about")} title={i18n.t('MENU.ABOUT')}>
                                <AboutPage {...this.props} />
                            </Tab>
                        </Tabs>
                    </Card.Body>
                    <Card.Footer className="p-1 bg-primary" >
                        <header className="d-flex flex-row align-items-center">
                            <div>
                                <Image className="ml-1 mr-3" src={require('../assets/logo_footer.png')} style={{ height: 80 }} roundedCircle />
                            </div>
                            <div className="d-flex flex-column">
                                <h5 className="m-0 text-white font-prompt" style={{ lineHeight: 1 }}>{i18n.t('PRODUCT.ADDRESS1')}</h5>
                                <div className="mt-1 text-white font-prompt d-none d-sm-block" style={{ marginTop: -3 }}>{i18n.t('PRODUCT.ADDRESS2')}</div>
                            </div>
                        </header>
                    </Card.Footer>
                </Card>
            </div >
        );
    }
}

export default HomePage;
