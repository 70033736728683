import React, { Component, Fragment } from 'react';
import { Card, Button, OverlayTrigger, Tooltip, Badge, Spinner, Image, Form } from 'react-bootstrap';
import { IoMdDownload, IoMdSearch, IoMdEye, IoMdAdd, IoMdRemove, IoMdExpand, IoMdPlayCircle, IoMdHome } from 'react-icons/io';
import { Document, Page, pdfjs } from 'react-pdf';
import FullHeight from '../components/FullHeight';
import FullScreen from '../components/FullScreen';
import InteractiveDialog from '../components/InteractiveDialog';
import SearchPDF from '../components/SearchPDF';
import ScrollContainer from 'react-indiana-drag-scroll';
import { httpHandleError } from '../framework';
import i18n from '../i18n';
import axios from '../axios';
import 'moment/locale/th';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Sidebar from 'react-sidebar';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class EBookPage extends Component {
    state = {
        book: null,
        pageCount: null,
        pageNumber: 1,
        pageNumberSelect: 1,
        dimensionHeight: 0,
        dimensionWidth: 0,
        pdfAreaHeight: 0,
        pdfAreaWidth: 0,
        pdfLoaded: false,
        displayMode: null,
        scale: 1,
        showInteractive: false,
        sidebarOpen: false,
        pdfAdjustWidth: null
    }
    initialData = this.initialData.bind(this);
    downloadFile = this.downloadFile.bind(this);
    bookViewed = this.bookViewed.bind(this);
    pageViewed = this.pageViewed.bind(this);
    setPageNumber = this.setPageNumber.bind(this);
    carouselWidth = this.carouselWidth.bind(this);
    onScale = this.onScale.bind(this);
    interactiveAvailable = this.interactiveAvailable.bind(this);
    pdfAdjustWidth = this.pdfAdjustWidth.bind(this);
    onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    onDocumentLoadError = this.onDocumentLoadError.bind(this);
    onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    onPageSelect = this.onPageSelect.bind(this);

    async componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        await this.initialData(query.get('id'));
    }

    downloadFile() {
        window.open(`${axios.defaults.baseURL}/file/download/${this.state.book?.pdf?.fileId}/attachment`);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    async initialData(bookId) {
        try {
            if (bookId) {
                const response = await axios.get(`book/detail/${bookId}`);
                if (response.data && response.data.book) {
                    this.setState({ book: response.data.book });

                } else {
                    this.props.alertDialog(i18n.t('COMMON.ERROR'), i18n.t('ERRORS.RECORDNOTFOUND'));
                }
            } else {
                this.props.alertDialog(i18n.t('COMMON.ERROR'), i18n.t('ERRORS.RECORDNOTFOUND'));
            }
        } catch (error) {
            this.props.alertDialog(i18n.t('COMMON.ERROR'), httpHandleError(error));
        }
    }

    async onDocumentLoadSuccess({ numPages }) {
        this.setState({ pdfLoaded: true, pageCount: numPages });
        await this.bookViewed();
        await this.pageViewed(0);
    }

    async bookViewed() {
        if (this.state.book && this.state.book._id) {
            try { await axios.get(`book/viewed/${this.state.book._id}`); } catch (error) { console.log(error); }
        }
    }

    async pageViewed(pageNumber) {
        if (this.state.book && this.state.book._id &&
            this.state.book.pdf && this.state.book.pdf.pages &&
            this.state.book.pdf.pages.length > 0 && this.state.book.pdf.pages[pageNumber] && this.state.book.pdf.pages[pageNumber]._id) {
            try { await axios.get(`book/page/viewed/${this.state.book._id}/${this.state.book.pdf.pages[pageNumber]._id}`); } catch (error) { console.log(error); }
        }
    }

    setPageNumber(pageNumber, callback) {
        this.setState({ pageNumber: pageNumber, pageNumberSelect: pageNumber }, callback);
    }

    onPageSelect(pageNumber) {
        if (pageNumber > 0 && pageNumber <= this.state.pageCount && this.state.pageNumber != pageNumber) {
            this.setState({ pageNumber: pageNumber, pageNumberSelect: pageNumber });
        } else {
            this.setState({ pageNumberSelect: this.state.pageNumber });
        }
    }

    onDocumentLoadError(error) {
        alert(JSON.stringify(error));
    }

    onScale(scaleType) {
        const increaseScale = 0.1;
        const minScale = 1;
        const maxScale = 2;
        var updateScale = this.state.scale;
        switch (scaleType) {
            case 'OUT': updateScale -= increaseScale; break;
            case 'IN': updateScale += increaseScale; break;
        }
        updateScale = (Math.round(updateScale * 100) / 100);
        updateScale = updateScale >= maxScale ? maxScale : updateScale;
        updateScale = updateScale <= 1 ? minScale : updateScale;
        if (this.state.scale != updateScale) {
            this.setState({ scale: updateScale }, () => {
                if (this.state.scale == 1) this.pdfAdjustWidth(this.state.pageNumber);
            });
        }
    }

    interactiveAvailable(pageNumber) {
        var isAvailable = false;
        if (this.state.book && this.state.book._id &&
            this.state.book.pdf && this.state.book.pdf.pages &&
            this.state.book.pdf.pages.length > 0 && this.state.book.pdf.pages[pageNumber] && this.state.book.pdf.pages[pageNumber].interactive) {
            var interactive = { ...this.state.book.pdf.pages[pageNumber].interactive };
            if (interactive.photos && interactive.photos.length > 0) isAvailable = true;
            else if (interactive.videos && interactive.videos.length > 0) isAvailable = true;
            else if (interactive.youtubes && interactive.youtubes.length > 0) isAvailable = true;
            else if (interactive.websites && interactive.websites.length > 0) isAvailable = true;
        }
        return isAvailable;
    }

    carouselWidth() {
        var actualWidth = (this.state.book.pdf.width / this.state.book.pdf.height) * this.state.pdfAreaHeight;
        if (this.state.pdfAdjustWidth && this.state.scale == 1) return this.state.pdfAdjustWidth;
        else return actualWidth;
    }

    pdfAdjustWidth(pageNumber) {
        var pdfPages = document.getElementsByClassName("react-pdf__Page");
        if (pdfPages && pdfPages.length > 0 && pdfPages[pageNumber] && pdfPages[pageNumber].children && pdfPages[pageNumber].children.length > 0) {
            console.log('pdfPages:', pdfPages[pageNumber].children[0].clientWidth);
            this.setState({ pdfAdjustWidth: pdfPages[pageNumber].children[0].clientWidth });
        }
    }

    render() {
        return (
            <div className="h-100">
                <InteractiveDialog
                    show={this.state.showInteractive}
                    onHide={() => this.setState({ showInteractive: false })}
                    interactive={this.state.book?.pdf?.pages[this.state.pageNumber - 1].interactive}
                />
                <Sidebar
                    sidebar={
                        <SearchPDF
                            pages={this.state.book?.pdf?.pages}
                            canSearchText={!!(this.state.book?.pdf?.pages || []).find((page) => page.text && page.text.length > 0)}
                            onSelect={(pageNumber) => this.onPageSelect(pageNumber)}
                            onClose={() => this.setState({ sidebarOpen: false })}
                        />
                    }
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    pullRight={true}
                    styles={{ sidebar: { background: "white", width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10, zIndex: 999 } }}
                >
                    <div />
                </Sidebar>
                <Card className="h-100 flex-fill" style={{ borderWidth: 0 }}>
                    <Card.Body className="d-flex pt-2 pr-3 pb-2 pl-3">
                        <div className="d-flex flex-column flex-fill">
                            <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mb-3"
                                style={{ borderBottomStyle: 'solid', borderBottomWidth: 3, borderBottomColor: "#2143a4" }}
                            >
                                <div className="mb-2 d-flex flex-column flex-glow">
                                    <div className="d-flex flex-row align-items-center">
                                        <h3 className="m-0 font-athiti-header mr-2">{this.state.book?.name}</h3>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip hide={isMobile ? 1 : 0}>
                                                    {`${i18n.t('MENU.HOME')}`}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="button-circle" variant="link" onClick={() => { this.props.history.push('/') }} >
                                                <IoMdHome className="text-primary" size={24} />

                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="font-athiti mr-1">
                                            {moment(this.state.book?.publishDate).add(543, 'year').format('LL')}
                                        </div>
                                        <Badge pill className="d-flex flex-row align-items-center" variant="secondary">
                                            <IoMdEye className="mr-1" />
                                            <div style={{ lineHeight: 0.5 }}>{this.state.book?.pdf?.pageView}</div>
                                        </Badge>
                                    </div>
                                </div>
                                <div className="mb-2 mr-3 font-athiti-subheader d-flex flex-row justify-content-center" style={{ height: 35 }}>
                                    {this.state.pdfLoaded &&
                                        <div className="d-flex flex-row align-items-center">
                                            <Form.Control
                                                className="mr-1"
                                                style={{ width: 30, paddingLeft: 5, paddingRight: 5, textAlign: 'center', backgroundColor: 'whitesmoke', color: 'black' }}
                                                type={'number'}
                                                name="pageNumberSelect"
                                                onBlur={(e) => this.onPageSelect(e.target.value)}
                                                onKeyDown={(e) => e.keyCode === 13 && this.onPageSelect(e.target.value)}
                                                onChange={(e) => this.setState({ pageNumberSelect: e.target.value })}
                                                size='sm'
                                                value={this.state.pageNumberSelect}
                                            />
                                            <div>
                                                {`/ ${this.state.book?.pdf?.pages?.length}`}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.pdfLoaded &&
                                    <div className="mb-2 d-flex flex-row align-items-center">
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip hidden={isMobile ? true : undefined}>
                                                    {i18n.t('COMMON.ZOOMOUT')}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="button-circle" variant="primary" onClick={this.onScale.bind(this, 'OUT')} >
                                                <IoMdRemove size={19} />
                                            </Button>
                                        </OverlayTrigger>
                                        <div className="font-athiti" style={{ width: 45, textAlign: 'center' }}>{Math.round(this.state.scale * 100)}%</div>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip hidden={isMobile ? true : undefined}>
                                                    {i18n.t('COMMON.ZOOMIN')}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="button-circle mr-3" variant="primary" onClick={this.onScale.bind(this, 'IN')} >
                                                <IoMdAdd size={19} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip hidden={isMobile ? true : undefined}>
                                                    {i18n.t('COMMON.DOWNLOAD')}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="button-circle mr-2" variant="primary" onClick={this.downloadFile}>
                                                <IoMdDownload size={19} />
                                            </Button>
                                        </OverlayTrigger>
                                        {!isMobile && <FullScreen />}
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip hidden={isMobile ? true : undefined}>
                                                    {i18n.t('COMMON.SEARCH')}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="button-circle mr-2" variant="primary" onClick={() => this.onSetSidebarOpen(true)}>
                                                <IoMdSearch size={19} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                }
                            </div>
                            <div className="d-flex flex-fill flex-column">
                                <FullHeight
                                    className="d-flex flex-row justify-content-center align-items-start"
                                    onSizeChange={(height, width, dimensionHeight, dimensionWidth) =>
                                        this.setState({
                                            pdfAreaHeight: height,
                                            pdfAreaWidth: width,
                                            dimensionHeight: dimensionHeight,
                                            dimensionWidth: dimensionWidth,
                                            displayMode: (dimensionWidth * 1.5) > dimensionHeight ? 'LANDSCAPE' : 'PORTRAIT'
                                        }, () => this.pdfAdjustWidth(this.state.pageNumber))
                                    }
                                >
                                    {
                                        this.state.book && this.state.book.pdf && this.state.book.pdf.fileId && this.state.displayMode &&
                                        <div className="pdf-theme">
                                            <Document
                                                className="shadow-backdrop"
                                                file={`${axios.defaults.baseURL}/file/download/${this.state.book?.pdf?.fileId}`}
                                                onLoadSuccess={this.onDocumentLoadSuccess}
                                                onLoadError={this.onDocumentLoadError}
                                                loading={i18n.t("COMMON.LOADINGPDF")}
                                            >
                                                <Carousel
                                                    showThumbs={false}
                                                    showIndicators={false}
                                                    showStatus={false}
                                                    swipeable={true}
                                                    // emulateTouch={true}
                                                    transitionTime={0}
                                                    selectedItem={this.state.pageNumber - 1}
                                                    preventMovementUntilSwipeScrollTolerance={true}
                                                    width={this.state.displayMode === 'LANDSCAPE' ? this.carouselWidth() : (this.state.pdfAdjustWidth || this.state.pdfAreaWidth)}
                                                    swipeScrollTolerance={200}
                                                    useKeyboardArrows={true}
                                                    onChange={(pageNumber) => {
                                                        if (this.state.pageNumber !== (pageNumber + 1)) {
                                                            console.log('Render Page:', pageNumber);
                                                            this.setPageNumber(pageNumber + 1, async () => {
                                                                await this.pageViewed(pageNumber);
                                                                if (this.state.pageNumber === pageNumber + 1) {
                                                                    this.pdfAdjustWidth(this.state.pageNumber);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {
                                                        [...Array(this.state.pageCount).keys()].map((pageNumber) => {
                                                            pageNumber += 1;
                                                            return (
                                                                <ScrollContainer id="pdf_container" key={pageNumber}
                                                                    style={{
                                                                        overflow: 'auto',
                                                                        scrollbarWidth: 0,
                                                                        height: this.state.displayMode === 'PORTRAIT' ? ((this.state.book.pdf.height / this.state.book.pdf.width) * this.state.pdfAreaWidth) : this.state.pdfAreaHeight
                                                                    }}
                                                                >
                                                                    <Page
                                                                        height={this.state.displayMode === 'LANDSCAPE' ? this.state.pdfAreaHeight : undefined}
                                                                        width={this.state.displayMode === 'PORTRAIT' ? this.state.pdfAreaWidth : undefined}
                                                                        pageNumber={pageNumber}
                                                                        scale={this.state.scale}
                                                                        renderTextLayer={false}
                                                                        renderAnnotationLayer={false}
                                                                        renderInteractiveForms={false}
                                                                        renderMode={[this.state.pageNumber - 1, this.state.pageNumber, this.state.pageNumber + 1].includes(pageNumber) ? "canvas" : "none"}
                                                                    />
                                                                    <Badge pill className="d-flex flex-row align-items-center" style={{ position: 'absolute', top: 3, right: 3, opacity: 0.8 }} variant="secondary">
                                                                        <IoMdEye className="mr-1" />
                                                                        <div style={{ lineHeight: 0.5 }}>{this.state.book?.pdf?.pages[pageNumber - 1].pageView || 0}</div>
                                                                    </Badge>
                                                                    {this.interactiveAvailable(pageNumber - 1) && this.state.pageNumber === pageNumber &&
                                                                        <div style={{ position: 'absolute', bottom: 10, right: 30 }}>
                                                                            <OverlayTrigger
                                                                                placement="top"
                                                                                overlay={
                                                                                    <Tooltip hide={isMobile ? 1 : 0}>
                                                                                        {`${i18n.t('COMMON.OPEN')} ${i18n.t('COMMON.INTERACTIVE')}`}
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Button className="button-circle shadow-logo" variant="primary" onClick={() => this.setState({ showInteractive: true })}>
                                                                                    <IoMdPlayCircle size={34} />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    }
                                                                </ScrollContainer>
                                                            );
                                                        })
                                                    }
                                                </Carousel>

                                            </Document>
                                        </div>
                                    }
                                </FullHeight>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div >
        );
    }
}

export default EBookPage;
