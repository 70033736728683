import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../i18n';

class AlertDialog extends Component {
    state = {
        show: false,
        title: '',
        message: '',
        onClose: null,
        unauthorize: false
    };
    alertDialog = this.alertDialog.bind(this);
    handleUnAuthorize = this.handleUnAuthorize.bind(this);

    alertDialog(title, message, onClose) {
        this.setState({
            show: true,
            title: title,
            message: message,
            onClose: onClose,
            unauthorize: message === i18n.t('ERRORS.UNAUTHORIZED')
        });
    }

    handleUnAuthorize() {
        if (this.state.unauthorize && this.props.setContext) this.props.setContext('userContext', null);
    }

    render() {
        return (
            <React.Fragment>
                <Modal show={this.state.show} onHide={() => {
                    this.setState({ show: false });
                    if (this.state.onClose) this.state.onClose();
                    this.handleUnAuthorize();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p>{this.state.message}</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            this.setState({ show: false });
                            if (this.state.onClose) this.state.onClose();
                            this.handleUnAuthorize();
                        }}>
                            {i18n.t('COMMON.OK')}
                        </Button>
                    </Modal.Footer>
                </Modal >
            </React.Fragment>
        );
    }
}

export default AlertDialog;