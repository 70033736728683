import i18n from './i18n';

const httpHandleError = (error, that) => {
    var errors = [];
    if (error) {
        if (error.response && error.response.data) {
            if (error.response.data.error && typeof (error.response.data.error) === 'string') {
                errors.push(i18n.t(error.response.data.error));
                if (error.response.data.error === 'ERRORS.UNAUTHORIZED') {
                    // logout(that, true);
                }
            }
            else if (error.response.data.error && typeof (error.response.data.error) === 'object') {
                errors.push(error.response.data.error.message);
            }
        } else if (error.status) {
            errors.push(error.status);
            errors.push(i18n.t('ERRORS.SOMETHINGWENTWRONG'));
        } else if (error.message) {
            errors.push(error.message);
        } else if (typeof error === 'string') {
            errors.push(i18n.t(error));
        }
    } else {
        errors.push(i18n.t('ERRORS.NETWORKERROR'));
    }
    return errors.join(' ');
};

const getCacheState = (key, defaultState) => {
    return JSON.parse(localStorage.getItem(key)) || defaultState;
};

const setCacheState = (key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
};

const getFileSize = (size) => {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export {
    httpHandleError,
    getCacheState,
    setCacheState,
    getFileSize
}