import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

export default function FullScreen(props) {
    const [fullScreenMode, setFullScreenMode] = useState(false);

    useEffect(() => {
        function onResize() {
            setFullScreenMode(window.screen.height === window.innerHeight);
        }

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [fullScreenMode]);

    return (
        <Fragment>
            {fullScreenMode ? null :
                <Row className="d-block">
                    <Col className="p-0 w-100 bg-primary-gradiant">
                        <header className="d-flex flex-row align-items-center" >
                            <div className="d-flex flex-fill flex-row justify-content-center">
                                <div className="d-flex flex-column align-items-center p-4">
                                    <Image src={require('../assets/logo_header.png')} style={{ height: 120 }}></Image>
                                </div>
                            </div>
                        </header>
                    </Col>
                </Row>
            }
        </Fragment>
    );
}