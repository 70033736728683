import React, { useState } from 'react';
import { Form, Card, Image } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import i18n from '../i18n';
import axios from '../axios';

export default function SearchPDF(props) {
    const [searchText, setSearchText] = useState("");
    return (
        <div className="p-3">
            <div className="mb-2 d-flex flex-row justify-content-between align-items-center">
                <h4 className="m-0 font-athiti-header">{i18n.t('COMMON.SEARCH')}</h4>
                <IoMdClose className="cursor-pointer" style={{ fontSize: 24 }} onClick={() => props.onClose && props.onClose()} />
            </div>
            {props.canSearchText &&
                <Form.Group>
                    <Form.Control
                        type={'text'}
                        name="search"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }} />
                </Form.Group>
            }
            <div className="mt-3 d-flex flex-row flex-wrap">
                {(props.pages || []).filter((page) => {
                    if (searchText && searchText.length > 0) {
                        return page.text && page.text.includes(searchText);
                    } else {
                        return true;
                    }
                }).map((page) => {
                    return (
                        <Card key={page._id} className="shadow mr-3 mb-3 cursor-pointer hover-zoom" onClick={() => {
                            if (props.onSelect) props.onSelect(page.pageNumber + 1);
                            if (props.onClose) setTimeout(() => props.onClose(), 300);
                        }}>
                            <Card.Body className="p-0 d-flex flex-column">
                                <div className="d-flex flex-column align-items-center mb-2">
                                    <Image className="book-cover" src={`${axios.defaults.baseURL}/file/download/${page.thumbnailId}`} style={{ height: 210, width: 151 }} />
                                </div>
                                <div className="pr-2 pb-2 pl-2 d-flex flex-column flex-fill">
                                    <div className="font-athiti-header" style={{ textAlign: 'center' }}>{page.pageNumber + 1}</div>
                                </div>
                            </Card.Body>
                        </Card>
                    );
                })}
            </div>
        </div >
    );
}