import React from 'react';
import { Image } from 'react-bootstrap';
import IFrame from '../components/IFrame';
const OTEPC_FACEBOOK_WIDGET = `<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOTEPCofficial&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=899150090105648" width="340" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`;
const OTEPC_YOUTUBE_CHANNEL = `https://www.youtube.com/channel/UCoQj3YobhI-m8ugRy37XDRQ`;

export default function SocialWidget(props) {
    return (
        <div className={props.className}>
            <IFrame className="shadow" src={OTEPC_FACEBOOK_WIDGET} />
            <div className="mt-2 d-flex flex-row">
                <div className="shadow mr-2 d-flex flex-row justify-content-center align-items-center"
                    style={{ backgroundColor: 'white', borderStyle: 'solid', borderWidth: 0, borderRadius: 0, width: 165, height: 165 }}>
                    <Image src={require(`../assets/qr_line.png`)} style={{ width: 150, height: 150 }} />
                </div>
                <Image className="shadow cursor-pointer hover-zoom"
                    src={require(`../assets/youtube.png`)}
                    style={{ width: 165, height: 165, borderRadius: 0 }}
                    onClick={() => window.open(OTEPC_YOUTUBE_CHANNEL)}
                />
            </div>
        </div>
    )
}   