import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppPage from './app/AppPage';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={AppPage}></Route>
      <Route path="/" component={AppPage}></Route>
      <Route path="/ebook" component={AppPage}></Route>
      <Route path="/about" component={AppPage}></Route>
      <Route component={() => <Redirect to="/" />}></Route>
    </Switch>
  );
}

export default App;
