import React, { Component } from 'react';
import { Card, Image, Container, Row, Col } from 'react-bootstrap';
import i18n from '../i18n';

class AboutPage extends Component {
    render() {
        return (
            <div className="h-100">
                <Card className="h-100" style={{ borderWidth: 0, borderRadius: 0 }}>
                    <Card.Body className="p-0">
                        <Container>
                            <Row>
                                <Col className="pt-4 p-0">
                                    <Image className="shadow" src={require('../assets/about.png')} style={{ width: '100%' }} />
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col className="p-0">
                                    <h4 className="font-prompt text-center">สำนักงานคณะกรรมการข้าราชการครูและบุคลากรทางการศึกษา (สำนักงาน ก.ค.ศ.)</h4>
                                    <h4 className="font-prompt text-center"> Office of the Teacher Civil Service and Educational Personnel Commission (OTEPC)</h4>
                                </Col>
                            </Row>
                            <Row className="mt-4 justify-content-start">
                                <Col className="p-0">
                                    <h4 className="p-0 font-prompt" style={{ textDecoration: 'underline' }}>วิสัยทัศน์ Vison</h4>
                                </Col>
                            </Row>
                            <Row className="mt-2 p-0">
                                <Col className="p-0">
                                    <h5 className="font-prompt" style={{ fontWeight: 'normal', textIndent: 30 }}>สำนักงาน ก.ค.ศ. เป็นองค์กรที่มีศักยภาพสูงในการพัฒนาระบบบริหารงานบุคคลของข้าราชการครูและบุคลากรทางการศึกษา เพื่อส่งเสริมคุณภาพการศึกษา</h5>
                                </Col>
                            </Row>
                            <Row className="mt-4 justify-content-start">
                                <Col className="p-0">
                                    <h4 className="p-0 font-prompt" style={{ textDecoration: 'underline' }}>พันธกิจ Mission</h4>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-5 p-0">
                                <Col className="p-0">
                                    <h5 className="font-prompt" style={{ fontWeight: 'normal', textIndent: 30 }}>1. จัดและพัฒนาระบบมาตรฐานการบริหารงานบุคคลสำหรับข้าราชการครูและบุคลากรทางการศึกษา</h5>
                                    <h5 className="font-prompt" style={{ fontWeight: 'normal', textIndent: 30 }}>2. เสริมสร้างประสิทธิภาพ และความก้าวหน้าของข้าราชการครูและบุคลากรทางการศึกษา</h5>
                                    <h5 className="font-prompt" style={{ fontWeight: 'normal', textIndent: 30 }}>3. รักษามาตรฐานและพิทักษ์ระบบคุณธรรมการบริหารงานบุคลสำหรับข้าราชการครูและบุคลากรทางการศึกษา</h5>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </div >
        );
    }
}

export default AboutPage;
