import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import i18n from '../i18n';

export default class ConfirmDialog extends Component {
    state = {
        show: false,
        title: '',
        message: '',
        primaryMessage: '',
        secondaryMessage: '',
        onConfirm: null,
        onClose: null
    };
    confirmDialog = this.confirmDialog.bind(this);

    confirmDialog(title, message, primaryMessage, secondaryMessage, onConfirm, onClose) {
        this.setState({
            show: true,
            title: title,
            message: message,
            primaryMessage: primaryMessage,
            secondaryMessage: secondaryMessage,
            onConfirm: onConfirm,
            onClose: onClose
        });
    }
    render() {
        return (
            <React.Fragment>
                <Modal show={this.state.show} onHide={() => {
                    this.setState({ show: false });
                    if (this.state.onClose) this.state.onClose();
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p>{this.state.message}</p></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.setState({ show: false });
                            if (this.state.onClose) this.state.onClose();
                        }}>
                            {this.state.secondaryMessage || i18n.t('COMMON.CANCEL')}
                        </Button>
                        <Button variant="primary" onClick={() => {
                            this.setState({ show: false });
                            if (this.state.onConfirm) this.state.onConfirm();
                        }}>
                            {this.state.primaryMessage || i18n.t('COMMON.CONFIRM')}
                        </Button>
                    </Modal.Footer>
                </Modal >
            </React.Fragment>
        );
    }
}