import React from 'react'
import { Modal, Button, Card, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import i18n from '../i18n';
import axios from '../axios';
import ModalImage from "react-modal-image";

export default function InteractiveDialog(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="font-athiti-header">{i18n.t('COMMON.INTERACTIVE')}</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.interactive?.photos && props.interactive?.photos?.length > 0 &&
                    <Card className="mb-3">
                        <Card.Header className="bg-primary-gradiant text-white font-athiti-700">{i18n.t('COMMON.PHOTO')}</Card.Header>
                        <Card.Body>
                            <div className="d-flex flex-row flex-wrap">
                                {props.interactive.photos.map((photoId) => {
                                    return (
                                        <ModalImage
                                            key={photoId}
                                            className="interactive-image shadow mr-3 mb-3 hover-zoom"
                                            showRotate={false}
                                            hideDownload={true}
                                            hideZoom={true}
                                            small={`${axios.defaults.baseURL}/file/download/${photoId}`}
                                            large={`${axios.defaults.baseURL}/file/download/${photoId}`}
                                        />
                                    );
                                })}
                            </div>
                        </Card.Body>
                    </Card>
                }
                {props.interactive?.videos && props.interactive?.videos?.length > 0 &&
                    <Card className="mb-3">
                        <Card.Header className="bg-primary-gradiant text-white font-athiti-700">{i18n.t('COMMON.VIDEO')}</Card.Header>
                        <Card.Body>
                            <div className="d-flex flex-row justify-content-center flex-wrap">
                                {props.interactive.videos.map((videoUrl, index) => {
                                    return (
                                        <ReactPlayer
                                            key={index}
                                            className='shadow mr-3 mb-3 h-auto'
                                            url={`${axios.defaults.baseURL}/file/stream/download/${videoUrl}`}
                                            config={{
                                                file: {
                                                    forceVideo: true,
                                                    attributes: {
                                                        controls: true
                                                    }
                                                }
                                            }}>
                                        </ReactPlayer>
                                    );
                                })}
                            </div>
                        </Card.Body>
                    </Card>
                }
                {props.interactive?.youtubes && props.interactive?.youtubes?.length > 0 &&
                    <Card className="mb-3">
                        <Card.Header className="bg-primary-gradiant text-white font-athiti-700">{i18n.t('COMMON.YOUTUBE')}</Card.Header>
                        <Card.Body>
                            <div className="d-flex flex-row justify-content-center flex-wrap">
                                {props.interactive.youtubes.map((youtubeUrl, index) => {
                                    return (
                                        <ReactPlayer
                                            key={index}
                                            className='shadow mr-3 mb-3'
                                            url={youtubeUrl}
                                            config={{
                                                youtube: {
                                                    playerVars: {
                                                        controls: true
                                                    }
                                                }
                                            }}>
                                        </ReactPlayer>
                                    );
                                })}
                            </div>
                        </Card.Body>
                    </Card>
                }
                {props.interactive?.websites && props.interactive?.websites?.length > 0 &&
                    <Card className="mb-3">
                        <Card.Header className="bg-primary-gradiant text-white font-athiti-700">{i18n.t('COMMON.WEBSITE')}</Card.Header>
                        <Card.Body>
                            <div className="d-flex flex-column">
                                {props.interactive.websites.map((websiteUrl, index) => {
                                    return <a key={index} target="_blank" rel="noopener noreferrer" href={websiteUrl}>{websiteUrl}</a>;
                                })}
                            </div>
                        </Card.Body>
                    </Card>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal >
    );
}