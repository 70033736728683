import React, { Component } from 'react';
import { Toast } from 'react-bootstrap';

export default class ToastDialog extends Component {
    state = {
        show: false,
        title: '',
        subtitle: '',
        message: '',
        onClose: null
    };
    showToast = this.showToast.bind(this);

    showToast(title, subtitle, message, onClose) {
        this.setState({
            show: true,
            title: title,
            subtitle: subtitle,
            message: message,
            onClose: onClose
        });
    }

    render() {
        return (
            <React.Fragment>
                <Toast
                    style={{
                        width: 300,
                        height: 100,
                        position: 'absolute',
                        top: 10,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        zIndex: 999
                    }}
                    onClose={() => {
                        this.setState({ show: false });
                        if (this.state.onClose) this.state.onClose();
                    }}
                    show={this.state.show}
                    delay={3000} autohide>
                    <Toast.Header>
                        <strong className="mr-auto">{this.state.title}</strong>
                        <small>{this.state.subtitle}</small>
                    </Toast.Header>
                    <Toast.Body>{this.state.message}</Toast.Body>
                </Toast>
            </React.Fragment>
        );
    }
}