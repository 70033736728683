import React, { Component, createRef } from 'react';
import AppRouter from './AppRouter';
import AppHeader from '../components/AppHeader';
import ToastDialog from '../components/ToastDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import AlertDialog from '../components/AlertDialog';
import FullHeight from '../components/FullHeight';
import { Container, Row, Col, ListGroup, Dropdown, Image, Button, Tabs, Tab } from 'react-bootstrap';
import { IoIosPerson, IoMdBook, IoMdInformationCircle, IoMdPie } from 'react-icons/io';
import { httpHandleError } from '../framework';
import i18n from '../i18n';
import axios from '../axios';
import Div100vh from 'react-div-100vh';

class AppPage extends Component {
    state = {
        locale: localStorage.getItem('language') || 'en',
        isInitialData: false
    };
    changeLanguage = this.changeLanguage.bind(this);
    refToastDialog = createRef();
    refConfirmDialog = createRef();
    refAlertDialog = createRef();

    componentDidMount() {
        this.setState({ isInitialData: true });
    }

    changeLanguage(value) {
        i18n.changeLanguage(value);
        localStorage.setItem('language', value);
        this.setState({ locale: value });
    }

    render() {
        return (
            <Div100vh className="d-flex flex flex-column" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <ToastDialog ref={this.refToastDialog} />
                <ConfirmDialog ref={this.refConfirmDialog} />
                <AlertDialog ref={this.refAlertDialog} />
                <Container className="d-flex flex-column flex-fill" fluid>
                    <AppHeader />
                    <Row className="d-flex flex-fill">
                        <Col lg={2} className="d-none d-lg-block" style={{ backgroundColor: '#f7f7f7' }} />
                        <Col lg={8} className="p-0 w-100 shadow-sm">
                            {this.state.isInitialData &&
                                <AppRouter
                                    showToast={this.refToastDialog?.current?.showToast}
                                    confirmDialog={this.refConfirmDialog?.current?.confirmDialog}
                                    alertDialog={this.refAlertDialog?.current?.alertDialog}
                                    {...this.props}
                                />
                            }
                        </Col>
                        <Col lg={2} className="d-none d-lg-block" style={{ backgroundColor: '#f7f7f7' }} />
                    </Row>
                </Container>
            </Div100vh>
        );
    }
}

export default AppPage;
