import React, { Component } from 'react';
import { Card, Badge, Image, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import { httpHandleError } from '../framework';
import { IoMdEye, IoMdDownload } from 'react-icons/io';
import axios from '../axios';
import i18n from '../i18n';
import moment from 'moment';
import { isMobile } from "react-device-detect";

class JournalList extends Component {
    state = {
        bookLists: []
    }
    initialData = this.initialData.bind(this);
    downloadFile = this.downloadFile.bind(this);
    openEBook = this.openEBook.bind(this);

    async componentDidMount() {
        await this.initialData();
    }

    async initialData() {
        try {
            const response = await axios.get(`book/list`);
            if (response.data && response.data.bookLists) {
                this.setState({ bookLists: response.data.bookLists });
            } else {
                console.log(i18n.t('COMMON.ERROR'), i18n.t('ERRORS.RECORDNOTFOUND'));
            }
        } catch (error) {
            console.log(i18n.t('COMMON.ERROR'), httpHandleError(error));
        }
    }

    downloadFile(fileId) {
        window.open(`${axios.defaults.baseURL}/file/download/${fileId}/attachment`);
    }

    openEBook(bookId) {
        const { history } = this.props;
        history.push({ pathname: `/ebook`, search: `?id=${bookId}` });
    }

    render() {
        return (
            <div className="d-flex flex-row pt-3 pr-4 pb-2">
                <div className="d-flex flex-column flex-fill">
                    <div className="mb-2 d-flex flex-row align-items-center" >
                        <div className="d-flex flex-column flex-glow">
                            <h3 className="m-0 font-athiti-header">{i18n.t('MENU.JOURNAL')}</h3>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-fill">
                        {
                            (this.state.bookLists || []).map((bookList) => {
                                var books = (bookList.books || []).map((book) => {
                                    return (
                                        <Card
                                            key={book._id}
                                            className="mt-3 mr-3 mb-3 cursor-pointer shadow hover-zoom"
                                            style={{ width: 151, borderWidth: 0 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.openEBook(book._id);
                                            }}
                                        >
                                            <Card.Body className="p-0 d-flex flex-column">
                                                <div className="d-flex flex-column align-items-center mb-2">
                                                    <Image className="book-cover" src={`${axios.defaults.baseURL}/file/download/${book.coverId}`} style={{ height: 210, width: 151 }} />
                                                </div>
                                                <div className="pr-2 pb-2 pl-2 d-flex flex-column flex-fill">
                                                    <div className="font-athiti-header" style={{ lineHeight: 1.2 }}>{book.name}</div>
                                                    <span className="flex-fill" />
                                                    <div className="font-athiti">{moment(book.publishDate).add(543, 'year').format('LL')}</div>
                                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                                        <Badge pill className="d-flex flex-row align-items-center" variant="secondary">
                                                            <IoMdEye className="mr-1" />
                                                            <span>{book.pdf?.pageView}</span>
                                                        </Badge>
                                                        {
                                                            book.pdf && book.pdf.fileId &&
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <Tooltip hide={isMobile ? true : undefined}>
                                                                        {i18n.t('COMMON.DOWNLOAD')}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.downloadFile(book.pdf.fileId);
                                                                }}>
                                                                    <IoMdDownload className="text-secondary" size="20" />
                                                                </div>
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    )
                                });
                                return (
                                    <div key={bookList.year} className="d-flex flex-column mb-2">
                                        <div className="d-flex flex-row">
                                            <div>
                                                <div className="d-flex flex-row align-items-center">
                                                    <h4 className="m-0 mr-2 font-athiti-header">{i18n.t('COMMON.YEAR')}</h4>
                                                    {/* <h4 className="m-0 font-athiti-header">{bookList.year + 543}</h4> */}
                                                    <Badge pill variant="primary" style={{ paddingTop: 3, paddingBottom: 3 }}>
                                                        <h5 className="m-0 font-athiti-header">{bookList.year + 543}</h5>
                                                    </Badge>
                                                </div>
                                                {/* <div className="bg-primary" style={{ height: 5, borderRadius: 100 }}></div> */}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row flex-wrap">
                                            {books}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default JournalList;
