import React, { Component } from 'react';

class FullHeight extends Component {
    state = {
        contentHeight: 0,
        contentWidth: 0,
        previousHeight: 0,
        previousWidth: 0
    }
    updateHeight = this.updateHeight.bind(this);
    resizeTimeout = null;
    updateHeight() {
        const { onSizeChange } = this.props;

        if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            var previousHeight = this.state.previousHeight;
            var previousWidth = this.state.previousWidth;
            var contentHeight = this.state.contentHeight;
            var contentWidth = this.state.contentWidth;
            this.setState({ previousHeight: this.state.contentHeight, previousWidth: this.state.contentWidth });
            if (previousHeight > 0 && previousWidth > 0 && contentHeight > previousHeight) {
                console.log('usePreviousHeightSize');
                this.setState({
                    contentHeight: previousHeight,
                    contentWidth: previousWidth
                }, () => {
                    onSizeChange(previousHeight, previousWidth, window.innerHeight, window.innerWidth);
                });
            }
            setTimeout(() => {
                if (this.divContent && this.divContent.parentElement) {
                    const height = this.divContent.parentElement.clientHeight;
                    const width = this.divContent.parentElement.clientWidth;
                    if ((this.state.contentHeight !== height) || (this.state.contentWidth != width)) {
                        this.setState({
                            contentHeight: height,
                            contentWidth: width
                        }, () => {
                            if (onSizeChange) onSizeChange(height, width, window.innerHeight, window.innerWidth);
                        });
                        console.log(this._reactInternalFiber.return.stateNode, this.state);
                    }
                }
            }, 0);
        }, 500);
    }

    componentDidMount() {
        setTimeout(() => { this.updateHeight(); }, 200);
        window.addEventListener("resize", this.updateHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateHeight);
    }

    render() {
        const { className, children, style } = this.props;
        return (
            <div
                className={className}
                ref={(divContent) => this.divContent = divContent}
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    height: this.state.contentHeight,
                    ...style
                }}>
                {this.state.contentHeight ? children : null}
            </div>
        )
    }
}

export default FullHeight;