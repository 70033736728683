import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EBookPage from './EBookPage';
import HomePage from './HomePage';
import AboutPage from './AboutPage';

export default class AppRouter extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={() => <HomePage {...this.props} />}></Route>
                <Route exact path="/ebook" component={() => <EBookPage {...this.props} />}></Route>
                <Route component={() => <Redirect to="/" />}></Route>
            </Switch>
        );
    }
}