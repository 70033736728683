import React from 'react';
import { Image } from 'react-bootstrap';
const OTEPC_FACEBOOK = `https://www.facebook.com/OTEPCofficial`;
const OTEPC_LINE = `https://line.me/R/ti/p/%40065wdlwl`;
const OTEPC_YOUTUBE_CHANNEL = `https://www.youtube.com/channel/UCoQj3YobhI-m8ugRy37XDRQ`;

export default function SocialSmallWidget(props) {
    return (
        <div className={props.className}>
            <div className="mt-2 d-flex flex-row justify-content-between">
                <Image className="shadow cursor-pointer mr-2" roundedCircle
                    src={require(`../assets/icon_facebook.png`)}
                    style={{ width: 50, height: 50 }}
                    onClick={() => window.open(OTEPC_FACEBOOK)}
                />
                <Image className="shadow cursor-pointer mr-2" roundedCircle
                    src={require(`../assets/icon_line.png`)}
                    style={{ width: 50, height: 50 }}
                    onClick={() => window.open(OTEPC_LINE)}
                />
                <Image className="shadow cursor-pointer mr-2" roundedCircle
                    src={require(`../assets/icon_youtube.png`)}
                    style={{ width: 50, height: 50 }}
                    onClick={() => window.open(OTEPC_YOUTUBE_CHANNEL)}
                />
            </div>
        </div>
    )
}   